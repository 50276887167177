module.exports = {
  // App Info
  appstore_link: "https://apps.apple.com/us/app/picnplace/id1443710293", // Enter App Store URL.
  playstore_link: "https://play.google.com/store/apps/details?id=com.picnplace", // Enter Google Play Store URL.
  google_analytics_ID: "", // Enter Google Analytics ID or ""
  presskit_download_link: "/", // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file).
  terms: "/tc.html",
  video_or_screenshot: "screenshot", // "screenshot" or "video"
  app_url: "https://picnplace.me", // Domain of your website without path_prefix.
  path_prefix: "/", // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name: "PicnPlace",
  app_price: "Free",
  app_description: "A photographer's dream list.",
  app_keywords: ["automatic", "gatsbyjs", "app", "landing page"],

  // Personal Info
  your_name: "rahul deshpande",
  your_link: "https://dishpanda.github.io",
  your_city: "the bay area, calif.",
  email_address: "picnplace.techsupp@gmail.com",
  linkedin_username: null,
  facebook_username: null,
  instagram_username: null,
  twitter_username: null,
  github_username: null,
  youtube_username: null,

  // Features List
  features: [
    {
      title: "Add a Place to Your List in 3 ways!",
      description:
        "Add new places to your dream list via Manual Entry, Explore, or with one click through On-The-Go!",
      fontawesome_icon_name: "plus",
    },
    {
      title: "Places Nearby & Map View",
      description:
        "See which places are within a 150 mile radius of your location in a list or on a map.",
      fontawesome_icon_name: "search-location",
    },
    {
      title: "Explore Module",
      description:
        "Take a look at popular images from the internet, and you can add those places to your list, too!",
      fontawesome_icon_name: "globe",
    },
    {
      title: "Alphabet Sort",
      description:
        "Easily scroll through your places, sorted by place name and address.",
      fontawesome_icon_name: "sort-alpha-down",
    },
    {
      title: "Add to Calendar",
      description:
        "Never miss a time-sensitive or seasonal shot by adding a place to a calendar event. You'll get a notification when it's time!",
      fontawesome_icon_name: "calendar-plus",
    },
    {
      title: "Search & Filter",
      description:
        "Quickly find the place you're looking for with the lightning-fast search feature.",
      fontawesome_icon_name: "search",
    },
  ],
  // FAQ List
  faq: [
    {
      question:
        "Why does it show a blank screen to me when I first login into the app?",
      answer:
        "PicnPlace is an app developed to help you create, maintain and access your own Dream List of Places where you would want to take pictures someday. We understand and respect the privacy aspect of a Dream List and hence want you to create it for yourself. So, start adding Places and see how many places are still left out there to capture.",
    },
    {
      question: "Why do I need to be connected to the internet for this app?",
      answer:
        "PicnPlace doesn't store any data on your device. All of your data is securely stored on the cloud. So, even if you lose or change your cell phone, you won\"t lose any of your Places. All you need to do is to login into the app on your new mobile device, with the same login credentials. To establish this connectivity, we ask you to be connected to internet.        ",
    },
    {
      question: "Why am I asked multiple times to enable Location Services?",
      answer:
        "We have used the internet and Location Services in the best way possible in PicnPlace to provide a unique experience to our users. The following services are based on the Location Services of your mobile device:",
      bullets: [
        '"Near me" screen showing distance from current location and its map view',
        'Add a Location or coordinates in "Add a Place" screen to geo tag a particular Place.',
        '"Add a Place On-the-Go" feature.',
      ],
    },
    {
      question: "Why is my toggle button black (and not orange)?",
      answer:
        'When the Location Services are switched off for your mobile device, the toggle button will turn black and will not allow you to navigate to "Near me" screen. Once you"ve enabled Location Services, the toggle button should now turn orange and you can use all of PicnPlace\'s features.',
    },
    {
      question: "How do I set a reminder for a time sensitive shot of a Place?",
      answer:
        'You can use the "Add to Calendar" feature of the app. Slide any Place tile to the left and then click the calendar icon. It will open the default calendar app on your device. You can schedule a reminder for yourself just like an event. The notification for the event will show up with the name of the Place and the words "MAP". Click the words MAP to look for directions on the default map application on your device.',
    },
    {
      question: "How does the Explore icon work?",
      answer:
        'The Explore icon is a window to the world. You can see some trending photographs with their location. We showcase only the pictures whose location is provided. If you want to add the location to your Dream List, simply click the "Add" button next to it and it will be added as a new Place in your Dream List. To view the reference picture anytime, you would need to be connected to the internet.',
    },
    {
      question: "What is the Add a Place On-the-Go feature?",
      answer:
        'While scouting for a new location or while on a family trip, you might eee a Place where you would want to come back some other time to capture your dream composition. It is important that you store that location somewhere so that you don"t forget, since in most cases, all these locations are lost and dreams are never fulfilled. We bring this new feature in PicnPlace. (Please refer to our tutorials to create the special button for this functionality). So, next time when you find such a Place, just click the "On-the-Go" button and save the location as a new Place – "PnP <Date><Time>". You can later on edit and fill in other relevant details, in order to make it more meaningful.',
    },
    {
      question: "Why should I provide my photography interests in my Profile?",
      answer:
        "We use your interests in photography to showcase some trending photographs in the Explore module.",
    },
  ],
  // Tutorials List
  tutorials: [
    {
        platform: "android",
        link: "https://www.youtube.com/embed/videoseries?list=PLpRhTfwDETc2R9gvAZ5saBwMRzCA1I35i"
    },
    {
        platform: "apple",
        link: "https://www.youtube.com/embed/videoseries?list=PLpRhTfwDETc2R9gvAZ5saBwMRzCA1I35i"
    }
  ],
  header_background: "rgba(0, 0, 0, 0.1)",
  topbar_title_color: "#ffffff",
  cover_overlay_color_rgba: "rgba(54, 59, 61, 0.8)",
  device_color: "black", // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color: "ffffff",
  primary_text_color: "#000",
  content_width: "1170px",
  font: `"Helvetica Neue", sans-serif`,
  link_color: "#eb6246",
  app_title_color: "#ffffff",
  app_price_color: "#ffffff",
  app_description_color: "#ffffff",
  feature_title_color: "#000000",
  feature_text_color: "#666666",
  feature_icons_foreground_color: "#eb6246",
  feature_icons_background_color: "#e6e6e6",
  social_icons_foreground_color: "#666666",
  social_icons_background_color: "#e6e6e6",
  footer_text_color: "#666666",
}
